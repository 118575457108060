<template>
  <main class="p-r">
    <!-- 轮播图 -->
    <wl-carousel />

    <!-- 悬浮栏 -->
    <section class="info">
      <section v-for="(item,index) in infoList" :key="index">
        <h4>
          <countTo v-if="item.endVal" :start-val="item.startVal" :end-val="item.endVal" :duration="1500" separator=''>
          </countTo> {{item.title}}
        </h4>
        <strong>{{item.desc}}</strong>
      </section>
    </section>

    <section class="page1">
      <article class="container">
        <wl-title title='产品中心' desc='打造实验室危险源、人员、仪器、试剂、方法、环境等多维一体的智慧实验室生态系统'></wl-title>
        <section class="flexWrap">
          <a class="box" v-for="(item,idx) in page1Data" :key="item.key" :href="item.path" :target="item.path.includes('http')?'_blank':'_self'">
            <section class="icon" :style="{'background-position': `-${idx*72}px 0px`}" v-lazy:background-image="'/static/img/home/p1-sprite.png'"></section>
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
          </a>
        </section>
      </article>
    </section>

    <section class="page2 bg" v-lazy:background-image="'/static/img/home/bg-page2.png'">
      <article class="container">
        <wl-title title='解决方案' desc='丰富的行业最佳实践方案，满足不同领域需求，让实验室更智能，易于管理'></wl-title>
        <section class="flexWrap">
          <section class="box" v-for="(item,index) in page2Data" :key="item.key" @mouseenter="mouseHandler(index)" @mouseleave="page2HoverId=null">
            <img v-lazy="'/static/img/home/'+item.key+'.png'" :alt="item.title">
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
            <section class="topBox flexColumn" v-show="page2HoverId===index">
              <a :href="kfUrl" target="_blank">在线获取解决方案</a>
            </section>
          </section>
        </section>
      </article>
    </section>

    <section class="page3 bg" v-lazy:background-image="'/static/img/home/bg-page3.png'">
      <article class="container">
        <section class="img">
          <img v-lazy="'/static/img/home/map.png'" alt="省、直辖市、自治区，服务网络覆盖全国">
          <section class="pulse"></section>
        </section>
        <section class="flexColumn faddein">
          <section class="box wow fadeInRightBig" v-for="(item,index) in page3Data" :key="item.key" data-wow-duration="1.5s" :data-wow-delay="0.05+index*0.02+'s'">
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
          </section>
        </section>
      </article>
    </section>

    <section class="page4">
      <section class="container">
        <wl-title title='客户案例' desc='已帮助300+高校、科研单位提高实验室信息化管理水平'></wl-title>
        <section class="imgBox">
          <section class="img transImg">
            <img class="img1" v-lazy="'/static/img/home/page4-1.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
            <img class="img2" v-lazy="'/static/img/home/page4-1.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
          </section>
          <section class="img transImg" style="top:107px">
            <img class="img1 right1" v-lazy="'/static/img/home/page4-2.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
            <img class="img2 right2" v-lazy="'/static/img/home/page4-2.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
          </section>
          <section class="img transImg" style="top:214px">
            <img class="img1" v-lazy="'/static/img/home/page4-3.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
            <img class="img2" v-lazy="'/static/img/home/page4-3.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
          </section>
          <section class="img transImg" style="top:321px">
            <img class="img1 right1" v-lazy="'/static/img/home/page4-4.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
            <img class="img2 right2" v-lazy="'/static/img/home/page4-4.png'" alt="已帮助300+高校、科研单位提高实验室信息化管理水平">
          </section>
        </section>
      </section>
    </section>

    <section class="page5 bg" v-lazy:background-image="'/static/img/home/bg-page5.png'">
      <article class="container">
        <wl-title title='选择新葡萄新京的理由' desc='实验室信息化解决方案提供商 · 新葡萄新京信息科技'></wl-title>
        <section class="flexWrap">
          <section class="box flexColumn" v-for="(item,index) in page5Data" :key="item.key" @mouseenter="mouseEnter(index)" @mouseleave="page5HoverId = null">
            <section class="icon" :style="{'background-position': page5HoverId===index? `-${index*92}px -92px`:`-${index*92}px 0px`}" v-lazy:background-image="'/static/img/home/p5-sprite.png'"></section>
            <h3>{{item.title}}</h3>
            <p>{{item.desc}}</p>
          </section>
        </section>
      </article>
    </section>

    <section class="page6">
      <article class="container">
        <wl-title title='新闻中心' :isNeedDesc=false class="wow fadeInUp" data-wow-duration="2s" data-wow-delay="0s"></wl-title>
        <section class="flex wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.6s">

          <section class="news mr-90">
            <h3>公司新闻<a href="/news/company">查看更多</a></h3>

            <a class="flexWrap firstBox" :href="newsList1[0].newsUrl?ajaxUrl+newsList1[0].newsUrl:`/news/detail/${newsList1[0].id}`" v-if="newsList1[0]" target="_blank">
              <section class="img"><img v-lazy="getRealPicUrl(newsList1[0].photo)" :alt="newsList1[0].title"></section>
              <section class="word flexColumn">
                <strong class="ellipsis-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">{{newsList1[0].title}}</strong>
                <p class="ellipsis wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">{{newsList1[0].content}}</p>
              </section>
            </a>

            <a class="box" v-for="(item,index) in newsList1" :key="index" v-show="index" :href="item.newsUrl?ajaxUrl+item.newsUrl:`/news/detail/${item.id}`" target="_blank">
              <section class="flexWrap wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">
                <strong class="ellipsis">{{item.title}}</strong>
                <p>{{item.fabushijian}}</p>
              </section>
              <p class="ellipsis wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">{{item.content}}</p>
            </a>

          </section>

          <section class="news">
            <h3>行业资讯<a href="/news/industry">查看更多</a></h3>

            <a class="flexWrap firstBox" :href="newsList2[0].newsUrl?ajaxUrl+newsList2[0].newsUrl:`/news/detail/${newsList2[0].id}`" v-if="newsList2[0]" target="_blank">
              <section class="img"><img v-lazy="getRealPicUrl(newsList2[0].photo)" :alt="newsList2[0].title"></section>
              <section class="word flexColumn">
                <strong class="ellipsis-2 wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.4s">{{newsList2[0].title}}</strong>
                <p class="ellipsis wow fadeInUp" data-wow-duration="2s" data-wow-delay="0.8s">{{newsList2[0].content}}</p>
              </section>
            </a>

            <a class="box" v-for="(item,index) in newsList2" :key="index" v-show="index" :href="item.newsUrl?ajaxUrl+item.newsUrl:`/news/detail/${item.id}`" target="_blank">
              <section class="flexWrap wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">
                <strong class="ellipsis">{{item.title}}</strong>
                <p>{{item.fabushijian}}</p>
              </section>
              <p class="ellipsis wow fadeInUp" data-wow-duration="2s" data-wow-delay="2s">{{item.content}}</p>
            </a>

          </section>
        </section>
      </article>
    </section>

    <section class="page7 bg" v-lazy:background-image="'/static/img/home/bg-page7.png'">
      <article class="container flexWrap">
        <section>
          <h2>联系我们</h2>
          <strong>400-169-8169 | 020-37267381</strong>
          <strong>3475907522</strong>
          <strong>yewu@weileit.com</strong>
          <strong>广州市天河区棠安路188号303、310室</strong>
        </section>
        <section>
          <h3>10秒提交需求，给您提供合适解决方案</h3>
          <section class="flexWrap">
            <el-input placeholder="请输入您的单位名称" v-model="submitInfo.company"></el-input>
            <el-input placeholder="请输入您的城市" v-model="submitInfo.city"></el-input>
          </section>
          <section class="flexWrap">
            <el-input placeholder="请输入您的姓名" v-model="submitInfo.name"></el-input>
            <el-input placeholder="请输入您的手机号码" v-model="submitInfo.mobile"></el-input>
          </section>
          <section class="needBox"><textarea class="need" placeholder="请输入您的需求信息" v-model="submitInfo.content"></textarea></section>

          <button class="primary" @click="submit">确认提交</button>
        </section>
      </article>
    </section>
  </main>
</template>

<script>
import WlCarousel from '../../components/wlCarousel'
import { wllib } from '../../utils/index'
import CountTo from 'vue-count-to'
import WOW from 'wowjs'

export default {
  components: { WlCarousel, CountTo },
  name: 'home',
  data() {
    return {
      ajaxUrl: Config.ajaxUrl,
      page1Data: [
        {
          key: 'home-product-1', title: '高校实验室安全规范管理平台',
          desc: '安全责任体系、安全管理制度、教育培训、安全准入条件保障、危险源等全方位、规范化、常态化管理',
          path: '/product/standard',
        },
        {
          key: 'home-product-2', title: '实验室安全分级分类管理系统',
          desc: '分级分类自动判定，实现“分级管理、分类施策、动态调整、精准防范、安全闭环”管理体制',
          path: '/product/classify',
        },
        {
          key: 'home-product-3', title: '实验室综合管理平台',
          desc: '实验室安全教育考试准入、危化品全生命周期管理、仪器预约共享管理、安全检查等智慧化解决方案',
          path: '/product/synthetical',
        },
        {
          key: 'home-product-4', title: '智慧实验室管理平台',
          desc: '集物联网智能硬件、业务系统、数据中台、智能预警应急预案于一体的智慧实验室管理平台',
          path: '/product/wise',
        },
        {
          key: 'home-product-5', title: '实验室信息管理系统LIMS',
          desc: '检测业务管理、样品管理、资源管理、质量管理、数据采集等，全流程业务管理，提高检测效率',
          path: '/product/lims',
        },
        {
          key: 'home-product-6', title: '试剂通-SAAS',
          desc: '针对中小实验室(课题组、企业内部实验室)推出的试剂耗材管理系统',
          path: 'http://sj.weileit.com/#/login',
        },
      ],
      page2Data: [
        { key: 'page2-2', title: '高校实验室管理系统', desc: '校级/院级/课题组' },
        { key: 'page2-1', title: '中小学实验管理系统', desc: '化工/生物/物理/实验室' },
        { key: 'page2-3', title: '科研院所实验室管理系统', desc: '中科院/农科院/医药/化工/环境/海洋研究院' },
        { key: 'page2-4', title: '医院实验室管理系统', desc: '三甲/高校附属/区域医院' },
        { key: 'page2-5', title: '事业单位实验室管理系统', desc: 'CDC/质检/食药/环境检测等单位' },
        { key: 'page2-6', title: '企业实验室管理系统', desc: '第三方检测/医药/生物医疗/基因检测' },
      ],
      page3Data: [
        { key: 'page3-1', title: '10年', desc: '高校、科研单位的成功选择' },
        { key: 'page3-2', title: '300+', desc: '科研单位选择新葡萄新京信息科技' },
        { key: 'page3-3', title: '15000+', desc: '课题组' },
        { key: 'page3-4', title: '450000+', desc: '师生用户在使用新葡萄新京产品' },
        { key: 'page3-5', title: '25个', desc: '省、直辖市、自治区，服务网络覆盖全国' },
      ],
      page5Data: [
        { key: 'home-reason-1', title: '专注行业定位', desc: '一直专注做实验室信息化管理整体解决方案，聚焦高校、医院、科研院所等领域' },
        { key: 'home-reason-2', title: '配置专业团队', desc: '拥有15年实验室信息化服务经验的老师深度了解用户需求，提供专业可靠的解决方案' },
        { key: 'home-reason-3', title: '产品高效全面', desc: '产品功能模块组件化，按需配置可拓展性强支持二次开发' },
        { key: 'home-reason-4', title: '提供优质服务', desc: '遵从本地化服务，专人跟进快速响应现场或远程在线支持，全程无忧' },
      ],
      page5HoverId: null,
      page2HoverId: null,
      infoList: [
        { title: '专注', desc: '实验室信息化管理' },
        { title: '大', desc: '产品线', startVal: 0, endVal: 3 },
        { title: '年', desc: '实验室信息化服务经验', startVal: 0, endVal: 15 },
        { title: '+', desc: '高校、科研单位的成功选择', startVal: 0, endVal: 300 },
      ],
      submitInfo: {},
      newsList1: [{ title: '', content: '', photo: '' }],
      newsList2: [{ title: '', content: '', photo: '' }],
      kfUrl: 'https://affim.baidu.com/unique_29804357/chat?siteId=14374257&userId=29804357&siteToken=520b588cd95edd77d27a035ef9054580',
    }
  },

  mounted() {
    new WOW.WOW({ live: true }).init()
    this.loadNewsData('公司新闻', 'newsList1')
    this.loadNewsData('行业新闻', 'newsList2')
  },

  methods: {
    mouseEnter(index) {
      this.page5HoverId = index
    },

    mouseHandler(index) {
      this.page2HoverId = index
    },

    submit() {
      if (!this.submitInfo.name || !this.submitInfo.mobile || !this.submitInfo.city || !this.submitInfo.company || !this.submitInfo.content) {
        wllib.global.WLMessage('请填写完整信息后再提交~', "warning");
        return
      }
      if(!this.checkMobile(this.submitInfo.mobile))
        return
      wllib.net.wlPost(Config.ajaxUrl + "/requirement/save", this.submitInfo, false, true).then((res)=>{
        if(res && res.code==200){
          wllib.global.WLMessage('提交成功~', "success");
          setTimeout(() => {
            this.submitInfo = {}
          }, 800);
        }
      })
    },

    loadNewsData(type, arrName) {
      let req = {
        newsType: type,
        isShow: true,
        isFirstPage: true, //首页推荐
        pageNum: 1,
        pageSize: 4
      }
      wllib.net.wlGet(Config.ajaxUrl + "/news/list", req).then((res) => {
        let { rows } = res.data
        this[arrName] = rows
        this[arrName].forEach((item) => {
          item.fabushijian = wllib.utils.dateFormat(item.publicityTime, '-')
          item.content = item.content.replace(/<.*?>|&nbsp;/ig, "")
        });
      })
    },

    checkMobile(value) {
      const regMobile = /^1[0-9]{10}$/
      if (!regMobile.test(value)) {
        // 不合法的手机号码
        wllib.global.WLMessage('手机号码格式不正确，请重新输入~', "warning");
        return false
      }
      return true
    },

    getRealPicUrl(url){
      return wllib.utils.getRealPicUrl(url)
    }
  }
}
</script>

<style lang='scss' scoped>
h3 {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  color: #262626;
  padding-top: 20px;
  padding-bottom: 14px;
}
strong {
  color: #3b3b3b;
  font-size: 20px;
}
p {
  font-size: 20px;
  color: #8e8e8e;
  line-height: 32px;
}
.info {
  height: 141px;
  width: 1260px;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(251, 253, 255, 0.9) 100%
  );
  box-shadow: 0px 8px 20px 1px rgba(164, 183, 207, 0.16);
  border-radius: 14px;
  position: absolute;
  top: 729px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: flex;
  align-items: center;
  span {
    font-size: 36px;
    font-weight: bold;
    display: inline-block;
    margin-right: -10px;
  }
  h4 {
    font-size: 36px;
    font-weight: bold;
    color: #4498ff;
    line-height: 36px;
  }
  strong {
    padding-top: 13px;
    padding-bottom: 0;
    color: #262626;
    font-size: 20px;
    line-height: 20px;
  }
  section {
    flex: 1;
    position: relative;
    text-align: center;
    &::before {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 1px;
      height: 73px;
      opacity: 0.4;
      content: "";
      background: #bfbfbf;
    }
    &:nth-last-child(1) {
      &::before {
        display: none;
      }
    }
  }
}
.page1 {
  height: 1031px;
  background: #fbfbff;
  padding-top: 206px;
  .icon {
    width: 72px;
    height: 72px;
  }
  .box {
    width: 400px;
    height: 280px;
    background: #ffffff;
    border-radius: 14px;
    padding: 0 28px;
    padding-top: 32px;
    margin-bottom: 30px;
    border: 1px solid #fff;
    cursor: pointer;
    display: block;
    p {
      line-height: 32px;
      text-align: left;
    }
    &:hover {
      box-shadow: 0px 8px 20px 1px rgba(176, 198, 225, 0.16);
      border: 1px solid #308dff;
      h3 {
        color: #308dff;
      }
    }
  }
}

.page2 {
  height: 1103px;
  padding-top: 136px;
  .box {
    width: 400px;
    background: #fff;
    border-radius: 14px;
    margin-bottom: 30px;
    padding-bottom: 28px;
    position: relative;
    h3 {
      padding-top: 22px;
      padding-bottom: 12px;
    }
    p {
      line-height: 20px;
    }
    &:hover {
      h3 {
        color: #308dff;
      }
    }
    .topBox {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 22, 48, 0.55);
      border-radius: 14px 14px 14px 14px;
      a {
        width: 239px;
        height: 66px;
        background: #308dff;
        border-radius: 14px 14px 14px 14px;
        display: block;
        color: #fff;
        font-size: 20px;
        line-height: 66px;
        cursor: pointer;
      }
    }
  }
}

.page3 {
  overflow: hidden;
  padding-top: 136px;
  height: 847px;
  position: relative;
  .img {
    width: 710px;
    padding-top: 24px;
    position: relative;
    .pulse {
      position: absolute;
      left: 59.75%;
      top: 84.2%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border: 3px solid #ff9900;
      border-radius: 50%;
      animation: pulse 2s linear infinite;
      animation-delay: 0.5s !important;
    }
  }
  .flexColumn {
    text-align: left;
    align-items: flex-start;
  }
  .faddein {
    position: absolute;
    top: 136px;
    left: 60.21%;
  }
  .box {
    h3 {
      font-size: 42px;
      line-height: 42px;
      color: #ff9900;
      padding-top: 30px;
      padding-bottom: 16px;
      font-weight: bold;
    }
    p {
      font-size: 24px;
      line-height: 24px;
      color: #262626;
    }
    &:nth-child(1) {
      h3 {
        padding-top: 0;
      }
    }
  }
}
@keyframes pulse {
  0% {
    width: 10px;
    height: 10px;
    opacity: 0;
  }
  40% {
    width: 18px;
    height: 18px;
    opacity: 0.4;
  }
  100% {
    width: 24px;
    height: 24px;
    opacity: 0;
  }
}

.page4 {
  padding-top: 136px;
  height: 828px;
  overflow: hidden;
  .container {
    width: 1604px;
  }
  .imgBox {
    height: 400px;
    position: relative;
    overflow: hidden;
  }
  .img {
    position: absolute;
    width: 100%;
    padding-bottom: 28px;
  }
  .img1 {
    position: absolute;
    animation: moveLeft1 36s infinite linear;
    left: 0px;
    top: 0px;
    width: 100%;
    right: auto;
    bottom: auto;
  }
  .img2 {
    position: absolute;
    animation: moveLeft2 36s infinite linear;
    left: 100%;
    top: 0px;
    width: 100%;
    right: auto;
    bottom: auto;
    float: left;
  }
  .right1 {
    animation: moveRight1 36s infinite linear;
  }
  .right2 {
    animation: moveRight2 36s infinite linear;
  }
}
@keyframes moveLeft1 {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}
@keyframes moveLeft2 {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes moveRight1 {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@keyframes moveRight2 {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
.page5 {
  height: 781px;
  padding-top: 136px;
  .box {
    width: 300px;
    height: 358px;
    background: #ffffff;
    border-radius: 14px;
    padding: 0 18px;
    justify-content: flex-start !important;
    padding-top: 48px;
    h3 {
      font-size: 24px;
      line-height: 24px;
    }
    p {
      text-align: left;
    }
    .icon {
      width: 92px;
      height: 92px;
    }
    &:hover {
      background: linear-gradient(
        180deg,
        #cbe4ff 0%,
        rgba(59, 157, 255, 0.75) 100%
      );
      h3 {
        color: #fff;
      }
      p {
        color: #fff;
      }
    }
  }
}

.page6 {
  overflow: hidden;
  padding: 136px 0;
  background: #fbfbff;
  .container {
    width: 1280px;
    .flex {
      flex-wrap: nowrap;
      justify-content: flex-start;
      text-align: left;
      width: 100%;
      padding-bottom: 72px;
      background: #ffffff;
      border-radius: 14px;
      padding-left: 64px;
      margin-top: 65px;
      padding-top: 63px;
      h3 {
        font-size: 24px;
        line-height: 24px;
        display: block;
        padding-left: 22px;
        padding-top: 0;
        padding-bottom: 34px;
        position: relative;
        &::before {
          position: absolute;
          top: -2px;
          left: 0;
          display: block;
          content: "";
          width: 9px;
          height: 28px;
          background: #3b9dff;
        }
      }
      strong {
        line-height: 20px;
        font-weight: 500;
        color: #262626;
      }
      .mr-90 {
        margin-right: 90px;
      }
      .news {
        width: 530px;
        .firstBox {
          padding-top: 40px;
          padding-bottom: 16px;
          transition: all 0.3s linear;
          cursor: pointer;
          &:hover {
            transform: scale(1.08);
          }
          .img {
            width: 184px;
          }
          .word {
            width: 320px;
            overflow: hidden;
            align-items: flex-start;
            justify-content: space-between;
            p {
              width: 320px;
              padding: 0;
              line-height: 20px;
            }
            strong {
              line-height: 28px;
            }
          }
        }
        .box {
          border-bottom: 1px solid #f0f0f0;
          transition: all 0.3s linear;
          cursor: pointer;
          display: block;
          &:nth-last-child(1) {
            border: none;
          }
          &:hover {
            transform: scale(1.05);
          }
          .flexWrap {
            padding-top: 25px;
            padding-bottom: 16px;
            strong {
              width: 80%;
            }
            p {
              padding: 0;
            }
          }
        }
        h3 {
          padding-bottom: 9px;
          position: relative;
          a {
            position: absolute;
            right: 0;
            font-size: 20px;
            color: #3b9dff;
          }
        }
        p {
          font-size: 16px;
          line-height: 16px;
          padding-bottom: 25px;
        }
      }
    }
  }
}

.page7 {
  height: 589px;
  padding-top: 125px;
  text-align: left;
  section {
    &:nth-child(2) {
      width: 630px;
    }
  }
  h2 {
    line-height: 40px;
    font-size: 40px;
    font-weight: 500;
    color: #262626;
    padding-bottom: 30px;
  }
  h3 {
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    margin-bottom: 34px;
  }
  strong {
    padding: 0;
    font-weight: 500;
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    padding-left: 67px;
    line-height: 42px;
  }
  .icon {
    width: 42px;
    height: 42px;
    margin-right: 25px;
  }
  .container .flexWrap {
    padding-bottom: 14px;
  }
  .need {
    width: 100% !important;
    height: 94px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    background: #fff;
    font-size: 20px;
    color: #8e8e8e;
    padding: 16px;
    padding-left: 30px;
    padding-top: 12px;
    font-family: Noto Sans S Chinese-Regular, Noto Sans S Chinese;
  }
  .primary {
    width: 100%;
    height: 52px;
    background: #0080ff;
    border-radius: 4px;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    margin-top: 34px;
    &:hover {
      opacity: 0.9;
    }
  }
  ::-webkit-input-placeholder {
    color: #b2b2b2;
  }
}

::v-deep.el-input {
  width: 300px;
  position: relative;
  &::before {
    content: "*";
    color: red;
    position: absolute;
    left: 16px;
    top: 16px;
    display: block;
    width: 4px;
    height: 40px;
    font-size: 20px;
  }
}
::v-deep .el-input--mini .el-input__inner {
  height: 52px;
  line-height: 52px;
  font-size: 20px;
  border: 1px solid #aaaaaa;
  padding-left: 30px;
  color: #8e8e8e;
}
::v-deep .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  border-color: #aaaaaa;
  outline: 0;
}
</style>
